import { DetectionApi, PredictionApi, AlgorithmApi } from './prediction.api.type';
import {
  DetectionInternal,
  PredictionInternal,
  AlgorithmInternal,
} from './prediction.internal.type';

export const toDetectionInternal = async (
  detectionApi: DetectionApi,
  displaySetUID?: string
): Promise<DetectionInternal> => {
  return {
    annotationId: await generateDetectionId(detectionApi),
    ...detectionApi,
    displaySetUID: displaySetUID,
    highlighted: false,
  };
};

export const toPredictionInternalWithDisplaySet = async (
  predictionApi: PredictionApi,
  displaySetUID?: string
): Promise<PredictionInternal> => {
  return {
    ...predictionApi,
    algorithm: toAlgorithmApi(predictionApi.algorithm),
    detections: await Promise.all(
      predictionApi.detections.map(detection => toDetectionInternal(detection, displaySetUID))
    ),
  };
};

export const toPredictionInternal = (predictionDTO: PredictionApi): PredictionInternal => {
  return toPredictionInternalWithDisplaySet(predictionDTO);
};

export const toDetectionApi = (detection: DetectionInternal): DetectionApi => {
  return {
    id: detection.id,
    type: detection.type,
    confidence: detection.confidence,
    points: detection.points,
    status: detection.status,
  };
};

export const toPredictionApi = (prediction: PredictionInternal): PredictionApi => {
  return {
    ...prediction,
    algorithm: toAlgorithmInternal(prediction.algorithm),
    detections: prediction.detections.map(toDetectionApi),
  };
};

export const toAlgorithmInternal = (algorithmInternal: AlgorithmInternal = {}): AlgorithmApi => {
  return {
    ...algorithmInternal,
  };
};

export const toAlgorithmApi = (algorithmApi: AlgorithmApi = {}): AlgorithmInternal => {
  return {
    ...algorithmApi,
  };
};

const generateDetectionId = async (detection: DetectionApi): Promise<string> => {
  let identifiers = '';

  if (detection.id) {
    return Promise.resolve(detection.id);
  }

  const points = [...detection.points];
  points.sort((a, b) => {
    if (a.x !== b.x) {
      return a.x - b.x;
    }
    if (a.y !== b.y) {
      return a.y - b.y;
    }
    return a.z - b.z;
  });

  identifiers += `${detection.type}_`;

  identifiers += `${detection.confidence}_`;

  identifiers += points.map(point => `${point.x},${point.y},${point.z}`).join(`_`);

  return hash(identifiers);
};

const hash = async (string: string): Promise<string> => {
  const utf8 = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(bytes => bytes.toString(16).padStart(2, '0')).join('');
  return hashHex;
};
